export const MenuItems = [
  // {
  //   menu: 'Home',
  //   path: '/Home',
  //   submenu: [
  //     // {
  //     //   title: 'Introduction',
  //     //   path: '/',
  //     //   className: 'dropdown-link'
  //     // },
  //     // {
  //     //   title: 'Mapa interactivo',
  //     //   path: '/InteractiveMap',
  //     //   className: 'dropdown-link'
  //     // }
  //   ]
  // },
  // {
  //   menu: 'Historia',
  //   path: '/',
  //   submenu: [
  //     {
  //       title: 'Guerra de los Misiles',
  //       path: '//history.com/news/cuban-missile-crisis-timeline-jfk-khrushchev',
  //       className: 'dropdown-link'
  //     }
  //   ]
  // },
  // {
  //   menu: 'Ciencia',
  //   path: '/',
  //   submenu: [
  //     {
  //       title: 'Costo de la vida',
  //       path: 'https://temas.sld.cu/costossalud/',
  //       className: 'dropdown-link'
  //     }
  //   ]
  // },
  {
    menu: 'Directorio Web',
    path: '/WebDirectory',
    submenu: [
      // {
      //   title: 'Ministerio de salud pública',
      //   path: 'https://salud.msp.gob.cu/',
      //   className: 'dropdown-link'
      // },
      // {
      //   title: 'Gaceta Oficial',
      //   path: 'https://www.gacetaoficial.gob.cu/es',
      //   className: 'dropdown-link'
      // },
      // {
      //   title: 'Portal web de economía (salud pública)',
      //   path: 'http://www.dncontabilidad.sld.cu/',
      //   className: 'dropdown-link'
      // }
    ]
  },
  // {
    // menu: 'Sobre Nosotros',
    // path: '/',
    // submenu: [
    //   {
        // title: 'Ministerio de salud pública',
        // path: 'https://salud.msp.gob.cu/',
        // className: 'dropdown-link'
      // }
    // ]
  // }
]
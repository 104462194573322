import React, { Children } from 'react'
import { ButtonSign } from '../buttons/ButtonSign'

import style from './Popup.module.scss'

function PopUp(props) {

  const { showModal, setShowModal, children } = props
  // const showHideClassName = showModal ? `${style.container}` : `${style.modal} ${style.displayNone}`;

  return (
    <React.Fragment>
      {
        showModal 
        ? (
          <div className={style.container}>
            <div className={style.popup}>
              <div className={style.popupInner}>
                { children }
                <div className={style.button}>
                  <ButtonSign 
                    className={ style.button }
                    link='/Home' 
                    onClick={() => setShowModal(false)}
                  >
                    Back to Home
                  </ButtonSign>
                </div>
              </div>
            </div>
          </div>
        ) :
          null
      }
    </React.Fragment>
  )
}

export default PopUp

import React from 'react'
import { Link } from 'react-router-dom'

import style from './Spotlight.module.scss'

function Spotlight({item}) {

  const url = 'spotlight-images/'

  return (
    
    <React.Fragment>
      <div className={style.spotlight}>
        <Link to={item.path}>
          <img src={url + item.img_url} alt='Historia de Cuba' />
        </Link>
        <div className={style.foot}>
          <div className={style.title}>
            <Link to={item.path}>
              {item.title}
            </Link>
          </div>
          <div className={style.description}>
            {item.description}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Spotlight

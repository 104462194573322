import React from 'react'
import { Link } from 'react-router-dom'

import style from './Button.module.scss'

const STYLES = [style.primary, style.outline]
const SIZES = [style.medium, style.large]

export const ButtonSign = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  link
}) => {

  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
  const checkLink = link ? link : '/'

  return(
    <Link to={link} className={style.buttonSign}>
      <button 
        className={`${style.buttonSign} ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      > 
        {children}
      </button>
    </Link>
  )
}

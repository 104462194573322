import React from 'react'
import { Link } from 'react-router-dom'

import style from './Button.module.scss'

const STYLES = [style.primary, style.outline]
const SIZES = [style.medium, style.large]

export const Button = ({
  children,
  type,
  // onClick,
  buttonStyle,
  buttonSize,
  loading
}) => {

  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
  const checkLoading = loading ? loading : false

  return(
   
      <button 
        // className={`${style.button} ${checkButtonStyle} ${checkButtonSize} ${style.noHover}` }
        className= {
          checkLoading 
          ? `${style.button} ${style.noHover}` 
          : `${style.button}`
        }
        type={type}
        // onClick={onClick}
        disabled={checkLoading}
      > 
        {children}
      </button>
   
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// import Dropdown from './Dropdown'

import { FaTimes, FaBars } from "react-icons/fa"
import LogoDesktop from '../../images/logo-desktop.png'
import LogoMobile from '../../images/logo-mobile.png'
import Menu from './Menu'

import { MenuItems } from '../../utilitis/MenuItems'
import { ButtonSign } from '../buttons/ButtonSign'

import style from './Navbar.module.scss'

function Navbar() {
  const [click, setClick] = useState(false)
  const [dropdown1, setDropdown] = useState(false)
  // const [dropdownHistory, setDropdownHistory] = useState(false)
  // const [dropdownScience, setDropdownScience] = useState(false)
  // const [dropdownWeb, setDropdownWeb] = useState(false)
  const [button, setButton] = useState(false)
  const [logo, setLogo] = useState(false)

  // Scroll event 
  document.addEventListener("scroll", function () {
    const navbar = document.querySelector(".navbar");
    const navbarHeight = 1;
  
    const distanceFromTop = Math.abs(
      document.body.getBoundingClientRect().top
    );
  
    // if (distanceFromTop >= navbarHeight)
    //   navbar.classList.add("fixed_top");
    // else 
    //   navbar.classList.remove("fixed_top");
  });


  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => { 
    if(window.innerWidth <= 960) {
      setButton(false)
      setLogo(false)
      setDropdown(false)
    } else {
      setButton(true)
      setLogo(true)
      setDropdown(true)
    }
  } 
  
  const onMouseEnter = () => {
    if(window.innerWidth <= 960) {     
      setDropdown(false)
    } else {      
      setDropdown(true) 
    }
  }

  const onMouseLeave =() => {
    if(window.innerWidth <= 960) {     
      setDropdown(true)
    } else {
      setDropdown(false)
    }
  }

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton)

  return (
    <>
      <nav className={style.navbar} >
        <div className={style.navbar_container} >
          <Link to='/Home' className={style.navbar_logo} onClick={closeMobileMenu}>
            {logo ? <img src={LogoDesktop} alt="Logo" /> : <img src={LogoMobile} alt="Logo" width="32px"/> }
          </Link>
          <div className={style.menu_icon} onClick={handleClick} >
            { click ? <FaTimes /> : <FaBars /> }
          </div>
         
          <ul className={click ? `${style.nav_menu} ${style.active}` : `${style.nav_menu}` }
            onMouseEnter={onMouseEnter}
            onClick={closeMobileMenu}
          >
            {MenuItems.map((item, index) => {
              return <Menu item={item} key={index} />
            })}
            <li className={style.nav_item} >
                <Link to='/Contactus' className={style.nav_links}    >
                  Contacto
                </Link>
            </li>
            {/* <li>
              <Link to='/sign-up' className={style.nav_links_mobile} onClick={closeMobileMenu}>
                Sign Up
              </Link>
            </li> */}
          </ul>
          {/* { button && <ButtonSign link={'/sign-up'} buttonStyle='outline'>SIGN UP</ButtonSign> } */}
        </div>
      </nav>
    </>
  )
}

export default Navbar

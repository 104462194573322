import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import LogoDesktop from '../../images/logo-desktop.png'
import LogoMobile from '../../images/logo-mobile.png'

import style from './Logo.module.scss'

function Logo() {
  const [click, setClick] = useState(false)
  const [logo, setLogo] = useState(true)

  const closeMobileMenu = () => setClick(false)

  return (
    <React.Fragment>
      <Link to='/Home' className={style.logo} onClick={closeMobileMenu}>
            {logo ? <img src={LogoDesktop} alt="Logo" /> : <img src={LogoMobile} alt="Logo" width="32px"/> }
          </Link>
    </React.Fragment>
  )
}

export default Logo

import React from 'react'
import style from './ContactUs.module.scss'

function TextError(props) {
  const { children } = props
    return (
        <div className={style.error}>
            { children }
        </div>
    )
}

export default TextError

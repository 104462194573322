export const webDirectoryData = [
  {
    name: 'Ministerio de Agricultura',
    website: 'https://www.minag.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Ciencia, Tecnología y Medio Ambiente de Cuba',
    website: 'https://www.citma.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Cultura',
    website: 'http://www.ministeriodecultura.gob.cu/es/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Educación',
    website: 'https://www.mined.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Economía y Planificación',
    website: 'https://www.mep.gob.cu/es',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Educación Superior',
    website: 'https://www.mes.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Energía y Minas',
    website: 'https://www.minem.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Finanzas y Precios',
    website: 'https://www.mfp.gob.cu/inicio/portada.php',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Industrias',
    website: 'https://www.mindus.gob.cu/es?page=1',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Justicia',
    website: 'https://www.minjus.gob.cu/es',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de la Construcción',
    website: 'https://www.micons.gob.cu/es',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de la Industria Alimentaria',
    website: 'https://www.minal.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de las Fuerzas Armadas Revolucionarias',
    website: 'https://www.minfar.gob.cu/en',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Relaciones Exteriores',
    website: 'http://www.minrex.gob.cu/es',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Salud Pública',
    website: 'https://salud.msp.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Trabajo y Seguridad Social',
    website: 'https://www.mtss.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio de Turismo',
    website: 'https://www.mintur.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Grupo Azucarero AZCUBA',
    website: 'https://www.azcuba.cu/?p=524',
    img_url: '',
    category: 'otros',
    description: ''
  },
  {
    name: 'Ministerio del Comercio Exterior y la Inversión Extranjera',
    website: 'https://www.mincex.gob.cu/index.php/portal/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio del Comercio Interior',
    website: 'https://www.mincin.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio del Interior',
    website: 'http://www.minint.gob.cu/',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Ministerio del Transporte',
    website: 'https://www.mitrans.gob.cu/es',
    img_url: '',
    category: 'ministerio',
    description: ''
  },
  {
    name: 'Instituto Nacional de Deportes, Educación Física y Recreación',
    website: 'http://www.inder.gob.cu/',
    img_url: '',
    category: 'instituto',
    description: ''
  },
  {
    name: 'Instituto Nacional de Recursos Hidráulicos',
    website: '/',
    img_url: '',
    category: 'instituto',
    description: ''
  },
  {
    name: 'Gaceta Oficial',
    website: 'https://www.gacetaoficial.gob.cu/es',
    img_url: '',
    category: 'otros',
    description: ''
  },
  {
    name: 'Portal web de economía (salud pública)',
    website: 'http://www.dncontabilidad.sld.cu/',
    img_url: '',
    category: 'otros',
    description: ''
  },
  {
    name: 'Enciclopedia cubana',
    website: 'https://www.ecured.cu/EcuRed:Enciclopedia_cubana',
    img_url: '',
    category: 'otros',
    description: ''
  },
  {
    name: 'Granma',
    website: 'http://www.granma.cu/',
    img_url: '',
    category: 'prensa',
    description: ''
  },
  {
    name: 'Trabajadores',
    website: 'http://www.trabajadores.cu/',
    img_url: '',
    category: 'prensa',
    description: ''
  },
  {
    name: 'Juventud Revelde',
    website: 'http://www.juventudrebelde.cu/',
    img_url: '',
    category: 'prensa',
    description: ''
  },
  {
    name: 'Asamblea Nacional del Poder Popular',
    website: 'https://www.parlamentocubano.gob.cu/',
    img_url: '',
    category: 'otros',
    description: ''
  },
  {
    name: 'Cuba debate',
    website: 'http://www.cubadebate.cu/',
    img_url: '',
    category: 'prensa',
    description: ''
  },
  {
    name: '',
    website: '',
    img_url: '',
    category: '',
    description: ''
  }
]
import React from 'react'
import style from './Button.module.scss'

const STYLES = [style.primary, style.outline]
const SIZES = [style.medium, style.large, style.fullWidth]
const FIXED = [style.false, style.true]

export const ClickButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonFixed,
  loading
}) => {

  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
  const checkfixed = buttonFixed ? style.buttonFixed : ''
  const checkLoading = loading ? loading : false

  return(
   
      <button 
        // className={`${style.button} ${checkButtonStyle} ${checkButtonSize} ${style.noHover}` }
        className= {
          checkLoading 
          ? `${style.button} ${checkButtonStyle} ${checkButtonSize} ${checkfixed} ${style.noHover}` 
          : `${style.button} ${checkButtonStyle} ${checkButtonSize} ${checkfixed}`
        }
        type={type}
        onClick={onClick}
        buttonFixed={buttonFixed}
        // disabled={checkLoading}
      > 
        {children}
      </button>
   
  )
}

import React from 'react'
import { useEffect } from 'react/cjs/react.development'

// let adsbygoogle = null

function MyLeaderBoardAd() {

  useEffect(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
  },[])

  return (
    <div>
      {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2482329472498020"
      crossorigin="anonymous"></script> */}
      {/* -- My First AdSense -- */}
      <ins class="adsbygoogle"
        style={{display: "block" }}
        data-ad-client="ca-pub-2482329472498020"
        data-ad-slot="6984674853"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
        {/* <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
        </script> */}

        {/* { adsbygoogle } */}
    </div>
  )
}

export default MyLeaderBoardAd

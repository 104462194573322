import React from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Navbar from './components/navigation-bar/Navbar'
import Home from "./pages/home/Home";

import WebDirectory from "./pages/web-directory/WebDirectory";
import InteractiveMap from './components/interactive-map/InteractiveMap'
import UnderConstruction from './pages/under-construction/UnderConstruction'
import ContactUs from "./pages/contactus/ContactUs";
import Footer from "./components/footer/Footer";

import { ProvinceData } from "./data/provinceData";

// import { FaBuffer, FaThemeisle, FaBattleNet } from "react-icons/fa";

import './styles/styles.scss'
// import './styles/globals/globals.scss'
// import './App.scss';

function App() {

  const routeComponents = ProvinceData.map(({path, component}, key) => 
    <Route exact path={path} component={component} key={key} />
  );

  return (
    <React.Fragment>
      <Router>
        <Navbar />
          <Switch>
            {/* <Route path='/' exact component={UnderConstruction} /> */}
            <Route path='/' exact component={Home} /> 
            <Route path='/Home' exact component={Home} /> 
            { ProvinceData.map(({path, component}, key) => 
              <Route exact path={path} component={component} key={key} />
            )} 
            <Route path='/WebDirectory' exact component={WebDirectory} />
            <Route path='/InteractiveMap' exact component={InteractiveMap}/>
            <Route path='/ContactUs' exact component={ContactUs} />
            <Route path='/c' exact component={UnderConstruction} />
          </Switch>
          <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;

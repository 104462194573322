import Habana from '../pages/provinces/Habana'
import Mayabeque from '../pages/provinces/Mayabeque'

export const ProvinceData = [
  {    
    path: 'pinar-del-rio',
    component: '/c',
    name: 'Pinar del Rio',
    downTown: 'Pinar del Rio',
    area: 8883.74,
    population: 589021  
  },
  {
    path: '/la-habana',
    component: Habana,
    name: 'La Habana',
    downTown: 'Centro Habana',
    area: 721,
    population: 2129553
  },
  {
    name: 'Artemisa',
    path: 'artemisa',
    downTown: 'Artemisa',
    area: 4004.27,
    population: 351855
  },
  {
    path: '/mayabeque',
    component: Mayabeque,
    name: 'Mayabeque',
    downTown: 'San José de las Lajas',
    area: 3743.8,
    population: 382459
  },
  {
    name: 'Matanzas',
    path: 'matanzas',
    downTown: 'Ciudad de Matanzas',
    area: 11798.02,
    population: 705775
  }, 
  {
    name: 'Villa Clara',
    path: 'villa-clara',
    downTown: 'Santa Clara',
    area: 8412,
    population: 615979
  },  
  {
    name: 'Cienfuegos',
    path: 'cienfuegos',
    downTown: 'Ciudad de Cienfuegos',
    area: 4180,
    population: 333843
  }, 
  {
    name: 'Sancti Spíritus',
    path: 'sancti-spiritus',
    downTown: 'Sancti Spíritus',
    area: 6777,
    population: 119893
  }, 
  {
    name: 'Ciego de Ávila',
    path: 'ciego-de-avila',
    downTown: 'Ciego de Ávila',
    area: 6972,
    population: 435326
  }, 
  {
    name: 'Camagüey',
    path: 'camaguey',
    downTown: 'Camagüey',
    area: 15386,
    population: 326128
  }, 
  {
    name: 'Las Tunas',
    path: 'las-tunas',
    downTown: 'Las Tunas',
    area: 6595,
    population: 537241
  }, 
  {
    name: 'Holguín',
    path: 'holguin',
    downTown: 'Ciudad de Holguín',
    area: 9216,
    population: 1021591
  }, 
  {
    name: 'Granma',
    path: 'granma',
    downTown: 'Granma',
    area: 8374,
    population: 817763
  }, 
  {
    name: 'Santiago de Cuba',
    path: 'santiago-de-cuba',
    downTown: 'Ciudad de Santiago de Cuba',
    area: 6228,
    population: 1056360
  }, 
  {
    name: 'Guantánamo',
    path: 'guantanamo',
    downTown: 'Guantánamo',
    area: 6168,
    population: 514910
  },   
  {
    name: 'Isla de la Juventud',
    path: 'isla-de-la-juventud',
    downTown: 'Isla de la Juventud',
    area: 241927,
    population: 84650
  }, 
]
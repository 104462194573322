import React from 'react'
import ProvinceMap from '../../components/interactive-map/LaHavanaMap'
import ProvinceMap1 from '../../components/interactive-map/Camaguey'

import MyLeaderBoardAd from '../../components/AdSense/MyLeaderBoardAd'

import style from './Provinces.module.scss'

function Habana() {

  const ads = process.env.NODE_ENV === 'production' &&
      <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" /> ;


  return (
    <main>
      <div className={style.container}>
        <div className={style.directory} >
          <div className={style.content} >
          <div className={style.map}>
          {/* <ProvinceMap />
          <ProvinceMap1 /> */}
        </div>
            <h1>La Habana</h1>
            <ul>
              <li><a href='https://www.lahabana.gob.cu/' target='_blank' rel='noreferrer'>Portal del Gobierno de La Habana</a></li>
              <li><a href='https://www.parlamentocubano.gob.cu/index.php/gobiernos-provinciales-del-poder-popular/' target="_blank"  rel='noreferrer'>Gobiernos Provinciales del Poder Popular</a></li>
              <li><a href='//cubatravel.cu/en/destinations/havana-cuba' target='_blank' rel='noreferrer'>Cuba Travel</a></li>
            </ul>

            {/* <div> */}
              {/* <iframe 
                allowfullscreen='' 
                width='99%' 
                height='400' 
                frameborder='0'
                style={{border: '5px'}}
                src="https://cubamaps.travel/minimalcenter/-79.57397460937501/21.642111228517322/7/es">
              </iframe> */}
              {/* 
              <iframe allowfullscreen="" width="50%" height="450" frameborder="0" src="https://cubamaps.travel/minimalcenter/-82.31472015380861/23.0571470720699/12/es"></iframe> */}
             </div>
            
            {/* <MyLeaderBoardAd /> */}


          {/* </div> */}
        </div>
      </div>
    </main>
  )
}

export default Habana

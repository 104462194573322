import React from 'react'
import { Link } from 'react-router-dom'
import style from './Dropdown.module.scss'

function DropDown({ item }) {
  return (
    <li className={style.container}>
      <Link to={item.path} className={style.linkContent}>
        {item.name}
      </Link>
    </li> 
  )
}

export default DropDown

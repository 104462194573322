import React , { useState } from 'react'
import { Link } from 'react-router-dom'
import InteractiveMap from '../../components/interactive-map/InteractiveMap'
import Spotlight from '../../components/spotlights/Spotlight'
import { ClickButton } from '../../components/buttons/ClickButton'
import { SpotlightMenu } from '../../utilitis/SpotlightMenu'
import { ProvinceData } from '../../data/provinceData'

import buttonStyle from '../../components/buttons/Button.module.scss'
import  style  from './Home.module.scss'
import DropDown from '../../components/dropdown/DropDown'

function Home() {
  const [showDropDownList, setShowDropDownList] = useState(true)
  const handleShowDropDownList = () => setShowDropDownList(!showDropDownList)

  return (
    <main>
      <div className={style.container}>
        {/* <div className={style.banner}> */}
          <img className={style.banner} src='images/parque-central-02.jpg' alt='parque central' />
        {/* </div> */}
        <div className={style.intro}>
          <div className={style.content}>
            {/* <h2>Introduction</h2> */}
            <p>Conozca las maravillas de esta hermosa isla del Mar Caribe, conocida desde la época colonial como "La Llave del Golfo", situada muy cerca de Haití, Bahamas, Jamaica, Estados Unidos y México. </p>

            <p>En este sitio, te invitamos a que aprendas a través de hechos, su larga historia, cultura, arte, literatura, avances cientificos, gastronomia y su música contagiosa. Su maravillosa flora y fauna, y sobre todo el calor de su gente, hacen que se convierta en uno de los destinos turísticos más codiciados del Caribe.</p>
            
            <p className={style.display_paragraph}>Pasa el cursor sobre el mapa para conocer los datos mas relevantes de cada una de sus provincias.</p>
          </div>
        </div>

        <div className={style.map}>
          <InteractiveMap />
        </div>

        <div className={showDropDownList 
          ? style.provinces
          : `${style.provinces} ${style.buttonFixed}`
        }>
          <ClickButton 
             buttonSize={buttonStyle.fullWidth}
             onClick={handleShowDropDownList}
            //  buttonFixed={true}
          >
            Provincias
          </ClickButton>

          <div className={showDropDownList 
            ? `${style.dropdownContent} ${style.hideContent}`
            : `${style.dropdownContent} ${style.showContent}`} >
              {ProvinceData.map((item, index) => {
              return (<DropDown item={item} key={index} />)
              })}
          </div >
        </div>
       
        <div className={style.spotlights}>
          {SpotlightMenu.map((item, index) => {
            return (<Spotlight item={item} key={index} />)
          })}
        </div>

      </div>
    </main>
  )
}

export default Home

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import TextError from './TextError'

import ReCAPTCHA from "react-google-recaptcha";

import style from './ContactUs.module.scss'
import Popup from '../../components/popup/Popup'

import { Button } from '../../components/buttons/Button'

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required!'),
  lastName: Yup.string().required('Required!'),
  email: Yup.string().email('Invalid email format').required('Required!'),
  emailSubject: Yup.string().required('Required!'),
  message: Yup.string().required('Required!')
})

function ContactUsForm() {

  const [loading, setLoading] = useState(true)
  // const [popup, setPopup] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [response, setResponse] = useState(null);  
  const [sentEmailCopy, setSentEmailCopy] = useState(false)

  const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(validationSchema)})

  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);
   
  //     if (!isScriptExist) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.src = url;
  //       script.id = id;
  //     //   script.onload = function () {
  //     //     if (callback) callback();
  //     //   };
  //       document.body.appendChild(script);
  //     }
   
  //     // if (isScriptExist && callback) callback();
  //   }
   
  //   // load the script by passing the URL
  //   loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
  //     function () {
  //       console.log("Script loaded!");
  //     });
  // }, []);

  const onSubmit = (data, e) => {
    console.log(data)
    setLoading(true)
    setShowModal(true)

    e.target.reset()

    // window.grecaptcha.ready(() => {
    //   window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
    //   // submitData(token);
    //   });
    // });
  }

  // const handleOnClick = () => setLoading(!loading)



  return (
    <form className={style.form}  onSubmit={handleSubmit(onSubmit)}>
      <div className={style.controls} >
        <input 
          name='firstName'
          type='text'
          placeholder='Nombre'
          {...register("firstName", {required: true} )}         
        />
        {errors.firstName && <TextError children={errors.firstName.message}/>}
      </div>

      <div className={style.controls} >
        <input 
          name='lastName'
          type='text'
          placeholder='Apellido'
          {...register("lastName", {required: true} )}         
        />
        {errors.lastName && <TextError children={errors.lastName.message}/>}
      </div>

      <div className={style.controls} >
        <input 
          name='email'
          type='email'
          placeholder='email'
          {...register("email", {required: true} )}         
        />
        {errors.email && <TextError children={errors.email.message}/>}
      </div>

      <div className={style.controls} >
        <input 
          name='emailSubject'
          type='text'
          placeholder='Subject'
          {...register("emailSubject", {required: true} )}         
        />
        {errors.email && <TextError children={errors.emailSubject.message}/>}
      </div>

      <div className={style.controls} >
        <textarea 
          name='message'
          type='textarea'
          placeholder='Message'
          rows='5' 
          maxLength="850"
          {...register("message", {required: true} )}         
        />
        {errors.content && <TextError children={errors.content.message}/>}
      </div>

      <div className={style.controls} >
        <input 
          id='sentEmailCopy'
          name='sentEmailCopy'
          type='checkbox'
          defaultChecked={sentEmailCopy}
          onChange={() => setSentEmailCopy(!sentEmailCopy)}
          {...register("sentEmailCopy", {required: true} )}         
        />
        <label className={style.checkboxLabel} htmlFor='sentEmailCopy'>Sent a email copy</label>
        {errors.sentEmailCopy && <TextError children={errors.sentEmailCopy.message}/>}
      </div>

      <ReCAPTCHA
        sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        onChange={() => setLoading(false)}
      />

      <div>
        {/* <button className={loading ? `${style.button} ${style.noHover}` : `${style.button}` }
          type='submit'
          disabled={loading}
        >
          Submit
        </button> */}

        <Button loading={loading} type='submit'>
          Submit
        </Button>

      </div>

      <Popup showModal={showModal} setShowModal={setShowModal}>
        <h2>Success</h2>
        <p>Your email was sent successfully</p>
        <hr />
        <p>We sent you an email with your information</p>
      </Popup> 
         
    </form>
  )
}

export default ContactUsForm


export const SpotlightMenu = [
  {
    title: 'Historia',
    path: '/c',
    img_url: 'mambi-334x235.jpg',
    description: 'Nuestra Historia'
  },
  {
    title: 'Ciencia',
    path: '/c',
    img_url: 'energia-334x235.jpg',
    description: 'El desarrollo de la Ciencia'
  },
  {
    title: 'Gastronomía',
    path: '/c',
    img_url: 'varadero-334x235.jpg',
    description: 'Deleite su gusto'
  }
]
import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import  style  from './Navbar.module.scss'

function Menu({ item }) {
  
  const [dropdownMenu, setDropdownMenu] = useState(false) 
  const [click, setClick] = useState(false)
  
  const showDropdownMenu = () => setDropdownMenu(!dropdownMenu)
  const handleClick = () => setClick(!click)

  const onMouseEnter = () => {   
    if(window.innerWidth <= 960) {     
      showDropdownMenu(false)  
    } else {      
      showDropdownMenu(true)          
    }
  }

  const onMouseLeave =() => {
    if(window.innerWidth <= 960) {
      showDropdownMenu(true)     
    } else {  
      showDropdownMenu(false) 
    }
  }

  return (   
      <li className={style.nav_item} >     
        <Link to={item.path} className={style.nav_links} 
          onClick={handleClick}          
          onMouseEnter={onMouseEnter} 
          onMouseLeave={onMouseLeave}
        >
          {item.menu} 
        </Link>
        
        <ul className={style.dropdown_menu}>    
          {click && item.submenu.map((item, index) => {
            return (              
              <li key={index}>
                <Link to={item.path} className={item.className}
                  // onMouseEnter={onMouseEnter} 
                  // onMouseLeave={onMouseLeave}
                >
                  {item.title}
                </Link>
              </li>            
            );
          })} 
        </ul>
      </li>  
  )
}

export default Menu

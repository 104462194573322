import React from 'react'

import UCImg from '../../images/website_under_construction_4.png'

import './UnderConstruction.css'

function UnderConstruction() {
  return (
    <main>
      <img className="under-construction-img" src={UCImg} alt="Under construction image" width="800"  />
       {/* usemap="#cubamap" */}
      
      <map name="cubamap">
        <area shape="rect" coords="571,295, 616, 317" href="/home" alt="valla" /> 
      </map>
    </main>
  )
}

export default UnderConstruction

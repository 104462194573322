import React from 'react'

import style from './Provinces.module.scss'

function Mayabeque() {
  return (
    <main>
      <div className={style.container}>
        <div className={style.directory} >
          <div className={style.content} >
            <h1>Mayabeque</h1>
            <p><a href="//cubatravel.cu/en/destinations/havana-cuba" target="_blank">Mayabeque</a></p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Mayabeque

import React from 'react'
import { webDirectoryData } from '../../data/webDirectoryData'
import { ascendent } from '../../utilitis/tools'


import style from './WebDirectory.module.scss'

function WebDirectory() {

  // const ascendent = (a, b) => {
  //   var nameA = a.name.toUpperCase(); 
  //   var nameB = b.name.toUpperCase(); 
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // } 

  const ministeries = webDirectoryData.filter(
    function(items) {
      return items.category === 'ministerio'
    }
  ).sort(ascendent)

  const institutos = webDirectoryData.filter(
    function(items) {
      return items.category === 'instituto'
    }
  ).sort(ascendent)

  const prensa = webDirectoryData.filter(
    function(items) {
      return items.category === 'prensa'
    }
  ).sort(ascendent)

  const otros = webDirectoryData.filter(
    (items) => {
      return items.category === 'otros'
    }
  ).sort(ascendent)

  return (
    <main>
      <div className={style.container}>
        <div className={style.directory} >
          <div className={style.content} >
            <h1>Directorio Web</h1>
            <h2>Ministerios</h2>
              {ministeries.map((item, index) => {
                return ( 
                  <ul  key={index}><li><a href={item.website} target='_new' rel='noreferrer '>{item.name}</a></li></ul>
                )
              })}
            <h2>Institutos</h2>
              {institutos.map((item, index) => {
                return ( 
                  <ul key={index}><li ><a href={item.website} target='_new' rel='noreferrer '>{item.name}</a></li></ul>
                )
              })}
            <h2>Presa Nacional</h2>
              {prensa.map((item, index) => {
                return ( 
                  <ul key={index}><li ><a href={item.website} target='_new' rel='noreferrer '>{item.name}</a></li></ul>
                )
              })}
            <h2>Otros Organismos</h2>
              {otros.map((item, index) => {
                return ( 
                  <ul key={index}><li ><a href={item.website} target='_new' rel='noreferrer '>{item.name}</a></li></ul>
                )
              })}
          </div >
        </div>
      </div>
    </main>
  )
}

export default WebDirectory

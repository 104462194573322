import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import FooterMenu from './FooterMenu'
import { MenuItems } from '../../utilitis/MenuItems'
import Logo from '../Logo/Logo'

import style from './Footer.module.scss'

function Footer() {
  const [click, setClick] = useState(false)

  return (
    <footer>
      <div className={style.container}>
        <div className={style.logo}><Logo /></div>
        <div className={style.menu}>
          <ul className={click ? `${style.menu}` `${style.active}` : `${style.menu}`} >
            {MenuItems.map((item, index) => {
              return <FooterMenu item={item} key={index} />
            })}
          </ul>
        </div>
        <div className={style.contact}>
          <Link to='/ContactUs' className={style.links }>Contacto</Link>
          {/* <p>1-123-456-7890</p> */}
          <p>contact@cubaenhechos.com</p>
        </div>
      </div>
      <hr />
      <div className={style.copyright}>&copy; Copyright 2021</div>
    </footer>
  )
}

export default Footer

import React from 'react'
import ContactUsForm from './ContactUsForm'

// import bannerImg from './parque-central-02.jpg'
import style from './ContactUs.module.scss'

function ContactUs() {
  const encoded = 'images/parque-central-02.jpg'
  return (
    <main>
      <div className={style.container}>
        <div className={style.banner }
          style={{ backgroundImage: `url(${encoded})` }}>
        </div>
        <div className={style.contactus}> 
          {/* <div className={style.contactusForm}> */}
            <h2 className={style.title}>Contactanos</h2>
            <ContactUsForm />
          {/* </div> */}
        </div>
      </div>
    </main>
  )
}

export default ContactUs
